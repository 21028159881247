if (module.hot) {
  module.hot.accept();
}

document.addEventListener('DOMContentLoaded', function () {
  var images = document.querySelectorAll('.gallery-line img');
  var lightboxContainer = document.querySelector(".lightbox-container");
  images.forEach(function (image) {
    image.addEventListener('click', function (event) {
      const imageToShow = event.target.getAttribute("data-lightbox");
      document.querySelector(".lightbox-img").style.backgroundImage = "url(" + imageToShow + ")";
      lightboxContainer.classList.add("--active");
    });
  });
  lightboxContainer.addEventListener("click", function() {
    lightboxContainer.classList.remove("--active");
  });
});
